
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
*{box-sizing: border-box;}
body{
  // $font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
h2 {
  $font-family: Roboto, "Helvetica Neue", sans-serif !important;
}
.mat-tab-group{
  $font-family: Roboto, "Helvetica Neue", sans-serif !important;
}
.mat-body, .mat-body-1, .mat-typography{
  $font-family: Roboto, "Helvetica Neue", sans-serif !important;
}
.mat-h2, .mat-title, .mat-typography h2{
  $font-family: Roboto, "Helvetica Neue", sans-serif !important;
}
.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-badge-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

*{ box-sizing: border-box;}
$dark-primary-text: #893CF9;
$light-primary-text: #da39f0;
$light-warn-text: #FFFFFF;
$dark-warn-text:#445dec;
$light-gray-text:#E1E7F1;
$ignite-blue: (
  50: #893CF9,
  400:#ffffff,
  700:#ffffff,
  contrast: (
    50: $light-warn-text,
    400: $light-warn-text,
    700: $light-warn-text
  )
);
$ignite-white-black: (
  50: #da39f0,
  400: #ffffff,
  700: #ffffff,
  contrast: (
    50: $light-warn-text,
    400: $light-warn-text,
    700: $light-warn-text
  )
);
$ignite-colors: (
  50: #ff0000,
  100: #ffa500,
  200: #EB5757,
  300: #ff0000,
  contrast: (
    50: $light-warn-text,
    100: $light-warn-text,
    200: $light-warn-text,
    300: $light-warn-text,
  )
);
$ignite-primary: mat.define-palette($ignite-blue, 50, 400);
$ignite-accent: mat.define-palette($ignite-white-black, 50, 400);

// The warn palette is optional (defaults to red).
$ignite-warn: mat.define-palette($ignite-colors, 50, 100, 200, 300);

// Create the theme object. A theme consists of configurations for individual
$font-family: Roboto, "Helvetica Neue", sans-serif;
// theming systems such as "color" or "typography".
$ignite-typography: mat.define-legacy-typography-config(
  $font-family: $font-family,
);
$ignite-theme: mat.define-light-theme((
  color: (
    primary: $ignite-primary,
    accent: $ignite-accent,
    warn: $ignite-warn,
  ),
  typography:$ignite-typography,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($ignite-theme);
