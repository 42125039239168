@import "variable.scss";
.ignite-editor{
  .fa-undo:before {
      content: "\e166" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-repeat:before {
      content: "\e15a" !important;
      font-family: "Material Icons" !important;
      font-size: 16px;
  }
  .fa-bold:before {
      content: "\e238" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-italic:before{
      content: "\e23f" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-underline:before{
      content: "\e249" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-strikethrough:before{
      content: "\e257" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-subscript:before{
      content: "\f111" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-superscript:before{
      content: "\f111" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-align-left:before{
      content: "\e236" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-align-center:before{
      content: "\e234" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-align-right:before{
      content: "\e237" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-align-justify:before{
      content: "\e235" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-indent:before{
      content: "\e23e" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-dedent:before, .fa-outdent:before{
      content: "\e23d" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-list-ul:before{
      content: "\e241" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-list-ol:before{
      content: "\e0ee" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-photo:before, .fa-image:before, .fa-picture-o:before{
      content: "\e412" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-video-camera:before{
      content: "\e04b" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-minus:before{
      content: "\e15b" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-remove:before, .fa-close:before, .fa-times:before{
      content: "\e5cd" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .fa-code:before{
      content: "\e4f3" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .color-label.foreground .fa-font:before{
      content: "\e40a" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .color-label.background .fa-font:before{
      content: "\e23a" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .background {
      background: #fff !important;
      color: #000 !important;
  }
  .angular-editor-toolbar-set .fa.fa-link:before {
      content: "\e157" !important;
      font-family: "Material Icons" !important;
      font-size: 16px !important;
  }
  #unlink- {
      display: none !important;
  }
  .ae-font .ae-picker-label {
      line-height: 30px !important;
  }
  .angular-editor-wrapper.show-placeholder {
      margin-bottom: 10px;
  }
  .upload-button{ margin-bottom:20px !important;}
  .angular-editor-textarea {
    min-height: 100% !important;
    max-height: 100% !important;
    min-height: 10rem !important;   
  }
}
.users-fireworks-edit{
  span{
    display: flex;
    align-items: center;
    gap: 5px;
      .mat-icon{
        font-size: 16px;
        width: auto;
        height: auto;
      }
  }
}
.img-box{
  margin-left: 25px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px #000;
  text-align: center;
  width: 100%;
  max-width: 100px;

      img {
          width: 100%;
          height: 80px;
          object-fit: contain;
      }
}
.extra-field {
  margin: 10px 0;
  mat-label{
    margin-bottom: 5px;
  }
}
.main-menu{
  .active{
    background-color: #DA39F0;
  }
}
.contribution-approving {
	cursor: pointer;
	color: #FFD45A;
  &.thumb-up {
    color: #0437F2;
  }
}
.mat-menu-panel {
  overflow: unset !important;
}
.mat-tab-body.mat-tab-body-active {
  overflow-y: hidden !important;
}
.content {
  padding: 20px 14px;
}
.mat-tab-body-content {
  height: 100%;
  overflow: unset !important;
}
.mat-toolbar-row, .mat-toolbar-single-row{
  display: block !important;
}
.mat-toolbar-single-row {
  white-space: initial !important;
}
.mat-toolbar.mat-accent {
  height: 100%;
  width: 100%;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-flow: wrap;
}
.col-md-2 {
  width: 16.66666667%;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-md-3 {
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-md-4 {
  width: 33.33333333%;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-md-5 {
  width: 41.66666667%;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-md-6 {
  width: 50%;
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-7 {
  width: 58.33333333%;
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-8 {
  width: 66.66666667%;
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-9 {
  width: 75%;
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-12 {
  flex: 0 0 auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.pl-0{
  padding-left: 0;
}
.pr-0{
  padding-right: 0;
}
.pt-0{
  padding-top: 0;
}
.pb-0{
  padding-bottom: 0;
}
.p-0{
  padding: 0;
}
.mat-form-field-infix{
  border-top: 0.3em solid transparent !important;
  vertical-align: middle;

}
.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(10%) !important;
}
/* You can add global styles to this file, and also import other style files */


html, body { height: 100%; }
body { margin: 0; font-family: $font-family; }
app-search-box {
  margin-right: auto;
}

a{color: $dark-warn-text;}
.d-block {
  display: block;
  width: 100%;
}
.text-center{
	text-align: center;
}
.danger {
	color: red;
}
.danger-bg {
	background: red;
	color: #fff;
}
.warning-bg {
	background: orange;
}
.spinner-container .mat-progress-spinner {
	margin: 50px auto;
}
.content{
  button{
    font-weight: 400;
  }
  h2 {
    font-weight: 400;
  }
  p {
    font-weight: 400;
  }
  span {
    font-weight: 400;
  }
  ul{
    li {
      font-weight: 400;
    }
  }
}
.tabs-email{
    span.mat-form-field-label-wrapper {
        top: -8px !important;
    }
}
.main-menu a {
  font-size: 16px !important;
  font-weight: 400;
}
body{
  $font-family: Roboto, "Helvetica Neue", sans-serif;
}
h2 {
  $font-family: Roboto, "Helvetica Neue", sans-serif;
}
.main-menu .mat-list-option:hover,
.mat-nav-list .mat-list-item:hover,
.mat-action-list .mat-list-item:hover{
	background: #7935df;
}
.mat-list-option:focus,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:focus {
  background: #da39f0;
}
.brands-child {
  padding-bottom: 10px;
}
.mat-dialog-container{
  padding: 0px !important;
}
.mat-dialog-actions{
  margin: 0px !important;
}
.mat-dialog-content{
  margin: 0px !important;
}
.mat-drawer-content {
  overflow: initial !important;
}
.alert-box{
	p{
		position: relative;
		border: solid 1px;
		border-radius: 5px;
		padding: 10px 10px 10px 36px;
		mat-icon{
			position: absolute;
			left: 5px;
			top: 8px;
		}
		span{
			display: block;
		}
	}
}
.name-img{
	margin-top: 15px;
}
table {
    width: 100%;
    img {
      width: 100%;
    }
    th {
      font-weight: 600 !important;
      font-size: 14px !important;
      $font-family: Roboto, "Helvetica Neue", sans-serif;
    }
    td {
      font-weight: 400;
      font-size: 13px !important;
      box-sizing: border-box;
      word-break: break-word;
      line-height: 17px;
      $font-family: Roboto, "Helvetica Neue", sans-serif;
      a{
        color:#000;
        $font-family: Roboto, "Helvetica Neue", sans-serif;
      }
    }
  }
  .mat-form-field {
    font-size: 14px;
    width: 100%;
  }
  td, th {
    padding:12px !important;
  }
  .table-header {
    background-color: #da39f0;
    padding: 16px 24px;
    margin-bottom: 20px;
    .brand-title{
      h2 {
        text-transform: uppercase;
        color: #fff;
        font-size: 20px;
        margin: 0px;
        $font-family: Roboto, "Helvetica Neue", sans-serif;
      }
    }
    .brands-button {
      float: right;
      button {
        margin: 0px 0 0 16px;
        $font-family: Roboto, "Helvetica Neue", sans-serif;
      }
    }
  }
  .mat-fab{
    width: 30px !important;
    height: 30px !important;
    display: block !important;
    margin: 0 0 7px 0px;
    .mat-icon {
      height: 30px !important;
      width: 28px !important;
    }
    .material-icons {
      font-size: 16px !important;
      line-height: 1px !important;
    }
    span{
      color: #000;
      $font-family: Roboto, "Helvetica Neue", sans-serif;
    }
}
.errors {
	color: red;
	margin: 1em 0;
}
.success {
	color: green;
	margin: 1em 0;
}
.errors span {
	display: block;
}
.list-footer:after {
	clear: both;
	content: "";
	display: block;
}

.list-footer .mat-paginator {
	float: right;
}

.youtube-icon{
	cursor: pointer;
}
.image-icon{
	cursor: pointer;
}

.list-footer .mat-form-field {
	float: left;
	max-width: 200px;
	margin-top: 10px;
	margin-left: 10px;
}

.list-footer {
	background: #fff;
}
::-webkit-scrollbar {
    width: 5px;
    height:5px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #757C94;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.search-record-load {
  position: absolute;
  right: 0;
  top: 12px;
}
.drop-down-menu{
    min-width: 150px !important;
    max-width: 300px !important;
    background-color: #893CF9 !important;
    min-height: 40px !important;
    .mat-raised-button {
        text-align: left !important;
        min-width: 100% !important;
        color: #fff !important;
        background-color: transparent !important;
        box-shadow: none !important;
        .mat-icon {
            margin: 0 6px 0 0;
        }
    }
    .mat-raised-button{
      display: block !important;
      margin: 4px 0 !important;
    }
}
.brands-button{
    .mat-focus-indicator.mat-raised-button.mat-button-base {
        background-color: transparent;
        color: #ffff;
        box-shadow: none;
    }
    .material-icons{
				width: auto;
				height: auto;
				font-size: 32px;
				line-height: 25px;
    }
		button {
			margin-left: 0 !important;
		}
}

.brands-button a, .brands-button button {padding: 0 13px !important;min-width: auto;}
.logo {
  width: 100%;
  max-width: 175px;
  height: 50px;
  object-fit: cover;
}
.logo-img {
  background-color: #fff;
  padding-top: 20px !important;
  text-align: center;
}
.main-menu {
    background-color: #893CF9;
    a {
        color: #fff !important;
        $font-family: Roboto, "Helvetica Neue", sans-serif;
        padding: 0 14px !important;
    }
    .mat-list-item-content{
      padding: 0px 0px !important;
      gap: 10px;
    }
    .drop{
        .mat-list-item-content{
            padding: 0px 0px !important;
            .mat-icon.right{
              position: absolute;
              right: 0;
            }
        }
    }
    .drop-down-menu {
      background-color: #9a5df7 !important;
      height: auto;
      .all-brands-link {
        display: block;
        font-size: 18px;
        padding: 0px 10px !important;
      }
      .mat-list-item-content {
          padding: 0 0 0 7px !important;
      }
      a {
        padding: 0 10px !important;
        $font-family: Roboto, "Helvetica Neue", sans-serif;
      }
    }
    .cron-drop-down{
      height: auto;
    }
}
.sidenav {
  width: 240px;
  height: 100%;
  background-color: #893CF9;
}
.mat-toolbar-row, .mat-toolbar-single-row {
  height: auto !important;
}
.brands-tabs{
  .mat-ripple.mat-tab-label.mat-focus-indicator.ng-star-inserted {
      width: 100%;
      max-width: 50%;
      background-color: #893CF9;
      opacity: 0.9;
      color: #fff;
  }
  .mat-ripple.mat-tab-label.mat-focus-indicator.ng-star-inserted.mat-tab-label-active {
    background-color: #da39f0;
    opacity: 0.9;
    color: #fff;
  }
}

.half-width-popup {
  background-color: transparent;
  width: 100%;
  max-width: 47%;
  margin: 0 10px;
  box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
}
.full-width-popup {
	background-color: transparent;
	width: 96%;
	margin: 0 10px;
	box-sizing: border-box;
}
.single-field {
  width: 330px;
}
.popup-heading.name{
  padding: 0px 0px;
}
.popup-heading {
  background-color: #da39f0;
  padding: 20px 20px;
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
  $font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-dialog-actions {
  padding: 0 20px !important;
}
.mat-focus-indicator.mat-button.mat-button-base.cancel-popup {
  background-color: #da39f0;
  color: #fff;
  $font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-focus-indicator.mat-button.mat-button-base.cancel-popup.blue-color {
  background-color: #893CF9;
  color: #fff;
  $font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-focus-indicator.mat-button.mat-button-base.popup-save {
  background-color: #893CF9;
  color: #fff;
  $font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-focus-indicator.mat-button.mat-button-base.popup-save.red-color {
  background-color: #ff0000;
  color: #fff;
  $font-family: Roboto, "Helvetica Neue", sans-serif;
}
.firmware-bottom {
  background-color: #fff;
  text-align: center;
  padding: 50px 0;
  margin-top: 300px;
  p {
    margin: 0;
    font-size: 15px;
    line-height: 22px;
    $font-family: Roboto, "Helvetica Neue", sans-serif;
  }
}
.user-back {
  border-bottom: solid 1px #ccc;
  padding-bottom: 20px;
  margin: 0 10px;
  $font-family: Roboto, "Helvetica Neue", sans-serif;
  .user-back-inner{
    .mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary {
      font-size: 16px;
      $font-family: Roboto, "Helvetica Neue", sans-serif;
    }
    .mat-icon.notranslate.material-icons.mat-icon-no-color {
      font-size: 20px;
      $font-family: Roboto, "Helvetica Neue", sans-serif;
    }
  }
}
.user-log{
  a {
      font-size: 14px;
      text-decoration: none;
      color: #883cf9;
      font-weight: bold;
      $font-family: Roboto, "Helvetica Neue", sans-serif;
  }
}
.mail-popup {
  height: 200px;
  .mat-form-field-wrapper {
    height: 100%;
    .mat-form-field-flex {
      height: 100%;
      .mat-form-field-infix {
        height: 100%;
        .mat-input-element.mat-form-field-autofill-control{
          height: 100%;
        }
      }
    }
  }
}
.brands-view {
  padding: 20px 40px;
  display: inline-block;
  p {
    font-size: 20px;
    margin: 0 0 30px 0;
    $font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  button {
    float: right;
    margin: 0 0px 0 10px;
    $font-family: Roboto, "Helvetica Neue", sans-serif;
  }
}
.mat-row:nth-child(2n+1){
	background-color: #fdfdfd;
}
.d-block{ display: block}
@media screen and (max-width: 1200px) {
  .mat-elevation-z8 {
    overflow: auto;
  }
}
@media screen and (max-width: 991px) {
  .half-width-popup {
      width: 100%;
      max-width: 100%;
      margin: 0 0px;
  }
  .firmware-bottom {
    margin-top: 100px;
  }
}
@media screen and (max-width: 767px) {
  .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11{
    width: 100%;
  }
  .mat-elevation-z8 {
    overflow: auto;
  }
  .mat-raised-button{
    min-width: auto;
  }
  .table-header{
    .col-md-6{
      width: 50%;
    }
    .brands-button{
      button {
        margin: 0px 0 0 0px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .header_filter {
      display: block !important;
      .mat-checkbox{ margin-bottom: 20px;}
  }
  .table {
    border: 0;
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tr {
      border-top:0px !important;
      border: solid 1px #ddd;
      display: block;
      margin-bottom:20px;
      height: auto !important;
    }
    td {
      border-bottom: 0px !important;
      border-top:1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align:left;
      height: auto !important;
      line-height: 42px !important;
      padding: 10px 20px !important;
      &:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }
      &:last-child {
        border-bottom: 0;
        border-radius:0px !important;
        border-right:none !important;

      }
      &:first-child{
        border-radius:0px !important;
        border-left:none !important;
      }
    }
  }
  .content {
    padding: 10px;
  }

  .table-header{
    margin-bottom: 5px;
    padding: 4px 12px;
    .brand-title{
      h2 {
        font-size: 14px;
      }
    }
    .brands-button{
      .material-icons {
        font-size: 20px;
        line-height: 22px;
      }
      button {
        padding: 0 !important;
        width: auto;
        min-width: auto;
      }
    }
  }


  table{
    max-width: 800px;
    th,td{
      padding: 10px !important;
      font-size: 12px !important;
    }
  }

  .mat-form-field {
      font-size: 12px;
  }
  mat-paginator.mat-paginator {
    width: 800px;
  }
  .main-menu a {
    font-size: 14px !important;
  }
  .radio-button-field{

    mat-label {
      font-size: 12px;
      margin-left: 0 !important;
    }
    mat-radio-button {
      padding-left: 0 !important;
      padding-right: 10px !important;
      span.mat-radio-label-content {
        font-size: 12px;
      }
    }
    mat-label {
      font-size: 12px;
    }
  }
  .popup-heading {
    padding: 6px 12px !important;
    font-size: 14px !important;
  }
}
